<template>
  <div class="c-last gradient--bg d-flex flex-column align-center">
    <p class="c-last__title white--text headline-2">
      Остались вопросы? Заполни анкету мы перезвоним
    </p>
    <d-btn class="c-last__btn headline-4" color="primary" @click="scrollToForm">
      Заполнить анкету
    </d-btn>
  </div>
</template>

<script setup lang="ts">


import {scrollToBlock} from "~/composible/scrollToBlock";

const scrollToForm = () => {
  const target = document.getElementById('c-form')
  scrollToBlock(target, 750, 0)
}
</script>

<style scoped lang="scss">
.c-last {
  padding: 40px;
  margin-top: 40px;
  @media (max-width: getBreakpoint(tablet)) {
    margin-top: 28px;
    padding: 32px 16px;
  }
  &__title {
    margin-bottom: 32px;
    @media (max-width: getBreakpoint(tablet)) {
      text-align: center;
      padding: 0 32px;
      margin-bottom: 20px;
    }
  }

  &__btn {
    padding: 20px 40px;
    border-radius: 36px;
    @media (max-width: getBreakpoint(tablet)) {
      height: 54px;
      width: 100%;
      max-width: 400px;
    }
  }
}
</style>